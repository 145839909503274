body {
    scroll-behavior: smooth;
}
div {
    outline: none !important;
}
.image-slider-container-single .slick-dots li button:before {
    color: #ffffff !important;
}
.slick-prev, .slick-next {
    top: 160px !important;
}
.slick-prev:before, .slick-next:before {
    color: rgba(0,0,0,0.5) !important;
    font-size: 25px !important;
}
.image-slider-container {
    width: 100%;
    margin: 80px auto 20px auto;
}
.image-slider-container-single {
    margin: 0 auto;
    width: 500px;
    overflow: hidden;
}
.conteiner p {
    margin-top: 10px;
}
.conteiner ul.accomplishment li {
    margin-left: 20px;
    margin-top: 10px;
}
.scroll_top {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 35px;
    height: 35px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    font-size: 30px;
    text-align: center;
    line-height: 30px;
    color: #ffffff;
    text-decoration: none;
    z-index: 222;
    transition: .3s ease-in-out;
}
.scroll_top:after {
    content: '↑';
}
.header{
    width: 100%;
    height: 80px;
    box-shadow: none;
    transition: background-color .3s linear;
    z-index: 10;
    position: fixed;
    top: 0;
    color: #080808;
    font-weight: 600;
    font-size: 16px;
}
.header.white{
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0) 0px 1px 3px;
}
.header__wrap{
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    max-width: 1200px;
    height: 80px;
}
.header__logo{
    height: 80px;
    width: auto;
}
.header__img{
    display: block;
    height: 100%;
}
.menu{
    height: 80px;
    padding: 0 30px;
}
.menu__wrap{
    height: 100%;
}
.menu__list{
    height: 100%;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
}
.menu__link{
    position: relative;
    text-decoration: none;
    color: #080808;
    transition: color 0.3s ease-in-out, opacity 0.3s ease-in-out;
    white-space: nowrap;
}
.menu__link:hover{
    color: #ffd014;
}
.menu__link:before{
    content: "";
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 4px;
    border-bottom: 1px solid #ffd014;
    opacity: 0;
    transition: bottom 0.3s ease-in-out, opacity 0.3s ease-in-out;
    white-space: nowrap;
}
.menu__link:hover:before{
    bottom: -1px;
    opacity: 1;
}
.main{
    height: 100vh;
    width: 100%;
    background: url("./img/logo/main_bg.jpg") no-repeat;
    background-size: cover;
    background-position: center;
    top: 0;
}
.main__wrap{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.about{
    height: 100vh;
}
.about__wrap{
    height: 100%;
    display: flex;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
}
.about__inner{
   max-width: 775px;
}
.about__title{
    text-transform: uppercase;
    margin-bottom: 25px;
    font-size: 16px;
    line-height: 1.35;
}
.about__text{
    font-weight: 300;
    font-size: 20px;
    line-height: 1.45;
}
.about__list{
    margin-left: 20px;
}
.why{
    background-color: #1a1a1a;
    height: 100vh;
    color: #ffffff;
}
.why__wrap{
    height: 100%;
    display: flex;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
}
.why__title{
    font-size: 42px;
    margin-bottom: 105px;
    font-weight: 600;
    line-height: 1.23;
}
.why__content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
}
.why__left{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 50px;
}
.why__left__item{
    display: flex;
    align-items: flex-start;
    gap: 30px;
    font-size: 16px;
    line-height: 1.55;
    font-weight: 300;
}
.why__left__icon{
    display: block;
    width: 80px;
    height: 80px;
    min-width: 80px;
}
.why__left__list{
    margin-left: 20px;
}
.why__right{
    width: 100%;
}
.why__right__inner{
    margin: 0 30px;
}
.why__right__img{
    display: block;
    width: 100%;
    height: auto;
}
.team{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.team__wrap{
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 150px;
    padding-bottom: 20px;
    width: 100%;
    max-width: 1200px;
}
.team__title{
    font-size: 42px;
    line-height: 1.23;
    margin-bottom: 40px;
}
.team__description{
    max-width: 560px;
    font-size: 24px;
    line-height: 1.5;
    font-weight: 300;
    text-align: center;
}
.team__list{
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
}
.team__item{
    box-sizing: border-box;
    padding: 20px;
    max-width: 350px;
    margin: 0 auto;
}
.team__item__photo{
    margin: 0 auto 20px auto;
}
.team__item__img{
    display: block;
    width: 100%;
    height: 100%;
}
.team__item__name{
    margin-bottom: 4px;
    font-size: 22px;
    line-height: 1.35;
    font-weight: 600;
    color: #000000;
}
.team__item__text{
    margin: 0 auto;
    color: #7a7a7a;
    font-size: 14px;
    line-height: 1.55;
    font-weight: 300;
}
.projects{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.projects__wrap{
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 150px 0;
    max-width: 1200px;
}
.projects__inner{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 150px;
}
.project__item{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.project__item__top{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 60px;
}
.project__item__title{
    color: #000000;
    font-size: 50px;
    font-family: 'Arial',Arial,sans-serif;
    line-height: 1;
    font-weight: 700;
    max-width: 765px;
}
.project__item__text{
    color: #000000;
    font-size: 16px;
    font-family: 'Arial',Arial,sans-serif;
    line-height: 1.55;
    font-weight: 400;
}
.project__item__logo{
    max-width: 300px;
}
.project__item__img{
    display: block;
    width: 100%;
    height: auto;
}
.projects__bottom{
    margin-top: 210px;
    width: 100%;
}
.projects__bottom__title{
    font-size: 42px;
    line-height: 1.23;
    font-weight: 600;
    color: #000000;
    margin-bottom: 105px;
}
.projects__bottom__list{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.projects__bottom__item{
    display: flex;
    align-items: flex-start;
    gap: 24px;
    max-width: 360px;
}
.projects__bottom__number{
    min-width: 35px;
    width: 35px;
    height: 35px;
    color: #000000;
    font-size: 16px;
    border-radius: 50%;
    background-color: #ffd014;
    display: flex;
    align-items: center;
    justify-content: center;
}
.projects__bottom__topic{
    font-size: 24px;
    line-height: 1.35;
    font-weight: 600;
    color: #000000;
    margin-bottom: 10px;
}
.projects__bottom__description{
    font-size: 15px;
    line-height: 1.55;
    font-weight: 300;
    color: #000000;
}
.clients{
    height: 100vh;
}
.clients__wrap{
    display: flex;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    height: 100%;
}
.clients__inner{
    width: 100%;
}
.clients__title{
    margin-bottom: 40px;
    font-size: 42px;
    line-height: 1.23;
    font-weight: 600;
    text-align: center;
}
.clients__description{
    max-width: 640px;
    font-size: 24px;
    line-height: 1.5;
    text-align: center;
    margin: 0 auto 105px auto;
}

.clients__list{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
}
.clients__item{
    width: 160px;
    height: auto;
}
.clients__img{
    display: block;
    width: 100%;
    height: auto;
}
.contacts{
    height: 100vh;
    background-color: #ebebeb;
}
.contacts__wrap{
    height: 100%;
    width: 1200px;
    display: flex;
    align-items: center;
    margin: 0 auto;
}
.contacts__title {
    margin-bottom: 60px;
    font-size: 42px;
    line-height: 1.23;
    font-weight: 600;
    color: #000000;
}
.contacts__list{
    margin-bottom: 40px;
}
.contacts__link{
    text-decoration: none;
    margin-bottom: 40px;
    font-size: 30px;
    line-height: 1.45;
    font-weight: 300;
    color: #000000;
}
.contacts__address{
    font-size: 18px;
    line-height: 1.55;
    font-weight: 300;
    color: #000000;
    max-width: 300px;
}
.contacts__policy{
    display: block;
    margin-top: 20px;
    color: #000000;
    max-width: 300px;
    text-decoration: none;
    cursor: pointer;
}
.contacts__policy:hover{
    text-decoration: underline;
}

.modal_requisites{
    display: none;
    z-index: 15;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
}
.modal_requisites.open{
    display: block;
}
.modal_requisites.open > .modal_requisites__bg{
    opacity: 1;
}
.modal_requisites.open > .modal_requisites__wrap{
    left: 0;
}
.modal_requisites__bg{
    opacity: 0;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    backdrop-filter: blur(5px);
    background: rgba(31,31,31,.84);
    transition: opacity;
    transition-delay: 0.3s;
}
.modal_requisites__wrap{
    display: block;
    position: absolute;
    top: 0;
    left: -600px;
    width: 500px;
    height: 100%;
    z-index: 25;
    background: #ffffff;
    padding: 40px 25px;
    transition: left;
    transition-delay: 0.3s;

}
.requisites__text{
    margin-top: 10px;
    max-width: 300px;
}
.modal_requisites__close {
    display: none;
}
@media (max-width: 768px) {
    .image-slider-container-single {
        width: 300px;
    }
    .slick-prev, .slick-next {
        top: 50% !important;
    }
    .slick-prev:before, .slick-next:before {
        color: rgba(0,0,0,0.5) !important;
        font-size: 20px !important;
    }

    .header {
        position: relative;
        height: 0;
    }
    .header__logo {
        height: 140px;
        max-width: 90%;
    }
    .header__wrap {
        flex-direction: column;
        max-width: 100%;
        height: 100vh;
        justify-content: center;
    }
    .menu {
        height: auto;
    }
    .menu__list {
        flex-direction: column;
    }
    .main_logo {
        display: none;
    }
    #about_block {
        height: auto;
        padding: 50px 30px;
    }
    #why_block {
        height: auto;
        padding: 50px 30px;
    }
    .why__left__icon {
        display: none;
    }
    .why__title {
        margin-bottom: 50px;
    }
    .why__content {
        flex-direction: column-reverse;
    }
    .why__right__inner {
        margin: 0 0 40px 0;
    }
    #team {
        height: auto;
        padding: 50px 30px;
    }
    .team__wrap {
        padding-top: 0;
    }
    .team__list{
        justify-content: center;
    }
    .team__item {
        width: auto;
        text-align: center;
        padding: 0;
    }
    .team__item__photo {
        width: 100%;
        height: auto;
    }
    .team__item__img {
        width: 100%;
        height: auto;
    }
    #projects {
        height: auto;
        padding: 50px 30px;
    }
    .projects__wrap {
        padding: 0;
        max-width: 100%;
    }
    .project__item__title {
        max-width: 100%;
    }
    h2 {
        font-size: 30px !important;
        text-align: center;
    }
    .project__item__logo {
        display: none;
    }
    .projects__inner {
        gap: 50px;
    }
    .projects__bottom {
        margin-top: 50px;
    }
    .projects__bottom__title {
        margin-bottom: 0;
    }
    .projects__bottom__list {
        flex-direction: column;
    }
    .projects__bottom__item {
        max-width: 100%;
        margin-top: 50px;
        gap: 15px;
    }
    #clients {
        height: auto;
        padding: 50px 30px;
    }
    .clients__list {
        padding: 0;
        flex-direction: column;
    }
    .clients__description {
        max-width: 100%;
        margin: 0 auto 30px auto
    }
    .clients__item {
        width: 65%;
        margin-top: 30px;
    }
    .clients__img {
        max-width: 350px;
        margin: 0 auto;
    }
    #contacts {
        height: auto;
        padding: 50px 30px;
    }
    .contacts__wrap {
        width: 100%;
        height: auto;
    }
    .contacts__link {
        font-size: 20px;
        line-height: 2;
    }
    .modal_requisites__wrap {
        width: 100%;
        padding: 0;
        height: auto;
    }
    .modal_requisites__inner {
        padding: 40px;
    }
    .modal_requisites__close {
        display: block;
        position: fixed;
        top: 5px;
        right: 5px;
        width: 30px;
        height:30px;
    }
    .modal_requisites__close:after {
        font-size: 24px;
        content: 'x';
        font-family: cursive,monospace;
        color: #484848;
    }
    .about__text, .project__item__text {
        font-size: 16px;
        font-family: inherit;
    }

    .team__description, .clients__description {
        font-size: 20px;
    }
    .scroll_top.visible {
        display: block;
        transition: .3s ease-in-out;
    }
}
